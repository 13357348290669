import styled from "styled-components";

export const TeamContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: hsl(195, 18%, 12%);
  margin: 0 -8px 0 -8px;

  @media screen and (max-width: 800px) {
    height: auto;
    padding: 4rem 0;
  }
`;

export const TeamWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr; // 1fr = 1 column
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  // As the screen decreases in size, reduce cards per row
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 0 0%;
  }
`;

export const Icons = styled.div`
  font-size: 1.4rem;
  margin: 0 0 0 0;
  padding: 0;
  opacity: 0;
  transform: translateY(10px);
  transition: 0.4s;

  @media screen and (max-width: 800px) {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const IconLink = styled.a`
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: black;
  transition: all 0.2s ease-in-out;
  margin: 0 0.5rem;
  color: white;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: violet;
  }
`;

export const TeamIcon = styled.img`
  height: 200x;
  width: 200px;
  margin-bottom: 10px;
  padding: 15px 5px 0 5px;
  transition: all 0.2s ease-in-out;
`;

export const TeamTitle = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const TeamTitleP = styled.p`
  font-size: 1.25rem;
  justify-content: center;
  color: white;
  margin: 0 0 35px 0;

  @media screen and (max-width: 1000px) {
    padding: 0 50px 0 50px;
    font-size: auto;
  }
`;

export const TeamH2 = styled.h2`
  font-size: 1.2rem;
  margin: 10px 0 10px 0;
  color: white;
`;

export const TeamRole = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: -10px 0 10px 0;
  color: #fff;
`;

export const TeamP = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 0 8px 0 8px;
`;

export const TeamCard = styled.div`
  background: linear-gradient(#131313 20%, #131313 50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  width: 230px;
  height: 305px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    /* box-shadow: 0 0 10px 1px white; */
    transform: scale(1.025);
  }

  &:hover ${Icons} {
    opacity: 1;
    transform: translateY(0px);
    transform: scale(1.025);
  }

  &:hover ${TeamIcon} {
    transform: scale(1.025);
  }
`;
