import { animateScroll as scroll } from "react-scroll";
import { FaDiscord, FaGithub, FaTelegram, FaTwitter } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  // FooterLinksContainer,
  // FooterLinkWrapper,
  // FooterLinkItems,
  // FooterLinkTitle,
  // FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  // WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        {/* <FooterLinksContainer>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="//exchange.soulswap.xyz" target="_blank">
                Github
              </FooterLink>
              <FooterLink to="//exchange.soulswap.xyz" target="_blank">
                Twitter
              </FooterLink>
              <FooterLink to="//exchange.soulswap.xyz" target="_blank">
                Discord
              </FooterLink>
              <FooterLink to="//exchange.soulswap.xyz" target="_blank">
                Telegram
              </FooterLink>
              <FooterLink to="//exchange.soulswap.xyz" target="_blank">
                News Telegram
              </FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
        </FooterLinksContainer> */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo
              onClick={toggleHome}
              to="hero"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              SOUL DEFI
            </SocialLogo>
            {/* <WebsiteRights>
              SoulSwap © {new Date().getFullYear()} All rights reserved.
            </WebsiteRights> */}
            <SocialIcons>
              <SocialIconLink
                href="https://github.com/SoulSwapFinance"
                target="_blank"
                aria-label="Github"
              >
                <FaGithub />
              </SocialIconLink>
              <SocialIconLink
                href="https://twitter.com/SoulSwapFinance"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="https://t.me/soulswapdefi"
                target="_blank"
                aria-label="Telegram"
              >
                <FaTelegram />
              </SocialIconLink>
              <SocialIconLink
                href="https://discord.gg/uDbZcHwzxX"
                target="_blank"
                aria-label="Discord"
              >
                <FaDiscord />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
