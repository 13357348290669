import styled from "styled-components";
import { Link } from "react-scroll";

export const InternalButton = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "violet" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 38px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    border-color: white;
    box-shadow: 0 0 10px 0 white;
    background: ${({ primary }) => (primary ? "white" : "violet")};
  }
`;

export const ExternalButton = styled.a`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "violet" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 38px")};
  color: ${({ darkButtonText }) => (darkButtonText ? "black" : "white")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    border-color: white;
    box-shadow: 0 0 10px 0 white;
    background: ${({ primary }) => (primary ? "white" : "violet")};
  }
`;

