import { animateScroll as scroll } from "react-scroll";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLinks>
            <NavLogo to="/" onClick={toggleHome}>
              SOUL DEFI
            </NavLogo>
          </NavLinks>

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>

          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                ABOUT
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="soulanomics"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                SOUL-A-NOMICS
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="seance"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                SEANCE
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="team"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                TEAM
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                SERVICES
              </NavLinks>
            </NavItem>
          </NavMenu>

          <NavBtn>
            <NavBtnLink to="//exchange.soulswap.finance" target="_blank">
              EXCHANGE
            </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
