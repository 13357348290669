// import { useState } from "react";
import Buns from "../../images/cropped-buns.png";
import DeGatchi from "../../images/cropped-degatchi.png";
import { FaTwitter, FaGithub } from "react-icons/fa";

import {
  TeamContainer,
  TeamWrapper,
  TeamCard,
  TeamIcon,
  TeamH2,
  TeamRole,
  Icons,
  IconLink,
} from "./TeamElements";

import { TopLine, Heading, Subtitle } from "../InfoSection/InfoElements";

const Team = () => {
  return (
    <TeamContainer id="team">
      <TopLine margin={"0 0 15px 0"}>Who is building SoulSwap?</TopLine>
      <Heading lightHeadline="true">FOUNDING SOULS</Heading>
      <Subtitle margin={"-10px 10px 35px 10px"} maxWidth={'400px'} maxWidthMobile={'auto'}>
        We have chosen the route of anonymity in order for the userbase to form
        perceptions of us through our actions, rather than our physical
        appearances, names, and where we are from.
      </Subtitle>
      <TeamWrapper>
        <TeamCard>
          <TeamIcon src={Buns} />
          <TeamH2>Enchantress Buns</TeamH2>
          <TeamRole>Co-Founder | CEO</TeamRole>
          <Icons>
            <IconLink href="https://twitter.com/0xBuns" target="_blank">
              <FaTwitter />
            </IconLink>
            <IconLink href="https://github.com/BunsDev" target="_blank">
              <FaGithub />
            </IconLink>
          </Icons>
        </TeamCard>

        <TeamCard>
          <TeamIcon src={DeGatchi} />
          <TeamH2>DeGatchi</TeamH2>
          <TeamRole>Co-Founder | CEO</TeamRole>
          <Icons>
            <IconLink href="https://twitter.com/DeGatchi" target="_blank">
              <FaTwitter />
            </IconLink>
            <IconLink href="https://github.com/DeGatchi" target="_blank">
              <FaGithub />
            </IconLink>
          </Icons>
        </TeamCard>
      </TeamWrapper>
    </TeamContainer>
  );
};

export default Team;
