import styled from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "hsl(195, 18%, 12%)" : "black")};
  margin: 0 -8px;
  height: ${({ height }) => (height ? height : "")};

  @media screen and (max-width: 1000px) {
    padding: 0 auto;
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 860px;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
`;

export const SpecialInfoRow = styled.div`
  display: center;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  // side by side grid column
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col1 col2'` : `'col2 col1'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col2 col2' 'col1 col1'`};
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  // side by side grid column
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col1 col2'` : `'col2 col1'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    margin: 0 20px;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;

  @media screen and (max-width: 768px) {
    padding: 0 0;
  }

  @media screen and (max-width: 480px) {
    padding: 0 15px;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 64px;
  text-align: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 64px;
    text-align: center;
  }
`;

export const TopLine = styled.div`
  color: violet;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin: ${({ margin }) => (margin ? `${margin}` : "0 0 16px 0")};
`;

export const Heading = styled.h1`
  margin: ${({ margin }) => (margin ? `${margin}` : "0 0 24px 0")};
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightHeadline }) => (lightHeadline ? "white" : "black")};

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : "auto")};
  margin: ${({ margin }) => (margin ? `${margin}` : "0 0 35px 0")};
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "black" : "white")};
  text-align: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: auto;
    padding-bottom: 0;
    justify-content: center;
    max-width: ${({ maxWidthMobile }) => (maxWidthMobile ? `${maxWidthMobile}` : "auto")};
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
