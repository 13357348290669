import brew from "../../videos/brew.gif";
import seance from "../../videos/seance.gif";
import boo from "../../videos/boo.gif";
// import { convertTypeAcquisitionFromJson } from "typescript";

export const section1 = {
  id: "about", // section
  lightBg: false, // background
  lightHeadline: true,
  lightTopLine: false, // text description
  topLine: "What makes us unique?",
  headline: "MULTICHAIN REWARDS",
  description:
    "AMM Protocols LACK cross-chain liquidity rewards. We invented a dynamic rewards system — splitting emissions across chains WITHOUT further diluting the value of SOUL.",
  buttonLabel: "ACCUMULATE",
  imgStart: true, // img on left or right side
  gif: brew,
  alt: "brew",
  darkButtonText: true,
  primary: true,
  darkText: false,
  to: "http://exchange.soulswap.finance",
};

export const section2 = {
  id: "soulanomics", // section
  lightBg: true, // background
  lightHeadline: true,
  lightTopLine: true, // text description
  topLine: "Where are the souls going?",
  headline: "SOUL-A-NOMICS",
  description:
    "250K SOUL is divided across deployed blockchains. Each blockchain carries a unique weight and rewards NEVER exceed 250K daily. With each new SOUL summoned, 75% goes to protocol incentives and 25% is split evenly between the DAO and the Protocol Funds for DAO management.",
  buttonLabel: "EXCHANGE",
  imgStart: false, // img on left or right side
  gif: boo,
  alt: "boo",
  darkButtonText: true,
  primary: true,
  darkText: false,
  to: "http://exchange.soulswap.finance",
};

export const section3 = {
  id: "seance", // section
  lightBg: false, // background
  lightHeadline: true,
  lightTopLine: true, // text description
  topLine: "Who controls our SOUL?",
  headline: "GOVERNANCE",
  description:
    "Summon together from the depths of the shroud to direct the life of SoulSwap. With SEANCE, you may vote to +/- features, update rewards allocation, necromance farms and pools from the grave, and direct the development focus on implementations before they are already decided/created. You are in control.",
  buttonLabel: "GOVERN",
  imgStart: true, // img on left or right side
  gif: seance,
  alt: "Seance",
  darkButtonText: true,
  primary: true,
  darkText: false,
  to: "http://enchant.soulswap.finance",
};

export const section4 = {
  id: "ceremony", // section
  lightBg: true, // background
  lightHeadline: true,
  lightTopLine: true, // text description
  topLine: "ABOUT OUR CEREMONY",
  headline: "HOW TO PARTICIPATE IN OUR OPENING CEREMONY",
  buttonLabel: "JOIN",
  imgStart: false, // img on left or right side
  gif: seance,
  alt: "Seance",
  darkButtonText: true,
  primary: true,
  darkText: false,
  to: "soulswap.finance",
};
