import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  BoxHeading,
  BoxBody,
  BoxFooter,
  Title,
  Description,
  Button,
} from "./ServicesElements";

import { TopLine, Heading, Subtitle } from "../InfoSection/InfoElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <TopLine margin={"0 0 15px 0"}>WHAT DOES SOULSWAP PROVIDE?</TopLine>
      <Heading lightHeadline="true">CROSS-CHAIN SERVICES</Heading>
      <Subtitle
        margin={"-10px 10px 35px 10px"}
        maxWidth={"500px"}
        maxWidthMobile={"auto"}
      >
        SoulSwap will save you time. <br /> <br />
        Compared to traditional services, where you will need to hop between
        platforms - searching, loading, connecting - we provide an all-in-one
        multi-chain platform, where you can quickly switch networks and use a
        multitude of services.
      </Subtitle>
      <ServicesWrapper>
        <ServicesCard>
          <BoxHeading>
            <Title>SOUL EXCHANGE</Title>
          </BoxHeading>
          <BoxBody>
            <Description>
              Swap tokens on multiple chains by connecting to different
              networks.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              TRADE
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>SOUL FARMING</Title>
          </BoxHeading>
          <BoxBody>
            <Description>
              Pair your tokens and provide liquidity to earn SOUL.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>SEANCE CIRCLES</Title>
          </BoxHeading>
          <BoxBody>
            <Description>
              Stake your SOUL to receive SEANCE so you can earn more SOUL.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance/stake"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>SPELLBOUND VAULTS</Title>
          </BoxHeading>
          <BoxBody>
            <Description>
              Compound your earnings by staking SEANCE to earn SOUL trading fees.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>ATOMIC SWAPS</Title>.
          </BoxHeading>
          <BoxBody>
            <Description>
              Trade your ERC20, 777, 721 or 1155 tokens publicly or privately.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>ART MARKETS</Title>
          </BoxHeading>
          <BoxBody>
            <Description>
              Auction or sell your token collections
              with perma royalities.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>ETERNAL LOANS</Title>
          </BoxHeading>
          <BoxBody>
            <Description>
              Provide tokens and earn interest from renting.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        <ServicesCard>
          <BoxHeading>
            <Title>LAUNCHPADS</Title>.
          </BoxHeading>
          <BoxBody>
            <Description>
              Fully customize your ERC20, 777, 721 or 1155 token/collection launch.
            </Description>
          </BoxBody>
          <BoxFooter>
            <Button
              background="white"
              href="http://exchange.soulswap.finance"
              target="_blank"
              aria-label="Exchange"
            >
              SOON
            </Button>
          </BoxFooter>
        </ServicesCard>

        {/* <ServicesCard>
          <Title>DAO SUMMONS</Title>
          <Description>
            Create and own our premade contracts, with no coding experience required, to use for your own needs.
          </Description>
          <Button>SOON</Button>
        </ServicesCard> */}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
