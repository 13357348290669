import React, { useState } from "react";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
// import HeroSection from '../components/HeroSection'
import InfoSection from "../components/InfoSection";
import { section1, section2, section3 } from "../components/InfoSection/Data";
import Services from "../components/Services";
import Team from "../components/Team";
import Footer from '../components/Footer'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      {/* <HeroSection {...section4}/> */}
      <InfoSection {...section1}/>
      <InfoSection {...section2}/>
      <InfoSection {...section3}/>
      <Team />
      <Services />
      <Footer />
    </>
  );
};

export default Home;
