import styled from "styled-components";

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesTitle = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesTitleP = styled.p`
  font-size: 1.25rem;
  justify-content: center;
  color: white;
  margin-bottom: 35px;

  @media screen and (max-width: 1000px) {
    padding: 0 50px 0 50px;
    font-size: auto;
  }
`;

// -----------------
//  BOX
// -----------------

export const BoxHeading = styled.div`
  padding: 0 0.5em;
  margin: 0;
  border-bottom: 1px solid #777;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
`;

export const BoxBody = styled.div`
  flex-grow: 1;
  padding: 0.5rem 0.5rem;
`;

export const BoxFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
`;

// -----------------
//  BOX CONTENT
// -----------------

export const Title = styled.h2`
  color: violet;
  font-size: 1rem;
  margin: 0;
  text-align: center;
`;

export const Description = styled.p`
  color: #aaa;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  transition: all 0.2s ease-in-out;
`;

export const Button = styled.a`
  border-radius: 10px;
  background: ${({ primary }) => (primary ? `${primary}` : "orange")};
  color: ${({ color }) => (color ? `${color}` : "black")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : "16px")};
  /* padding: 8px 20px; */
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;

  height: 35px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(10px);
  transition: 0.4s;

  @media screen and (max-width: 800px) {
    opacity: 1;
    transform: translateY(0);
  }
  &:hover {
    opacity: 75%;
  }
`;

export const ServicesContainer = styled.div`
  height: 850px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  margin: 0 -8px 0 -8px;

  @media screen and (max-width: 1000px) {
    height: 1100px;
  }

  @media screen and (max-width: 750px) {
    height: 1900px;
  }

  @media screen and (max-width: 425px) {
    height: 2000px;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; // 1fr = 1 column
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  // As the screen decreases in size, reduce cards per row
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: #131313;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 150px;
  width: 200px;
  padding: 20px 20px 5px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1.5px solid #333;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 10px violet;
    border: 1.5px solid violet;
    cursor: pointer;
  }

  &:hover ${BoxHeading} {
    border-bottom: 1px solid violet;
  }

  &:hover ${BoxBody} {
    display: block;
    opacity: 1;
  }

  &:hover ${Title} {
    color: violet;
    text-shadow: 0 0 10px violet;
    transition: all 0.3s ease-in-out;
  }

  &:hover ${Description} {
    color: white;
  }

  &:hover ${Button} {
    border-color: violet;
    box-shadow: 0 0 10px 0 violet;
    background: ${({ primary }) => (primary ? `${primary}` : "violet")};
    opacity: 1;
    transform: translateY(0px);
  }
`;
