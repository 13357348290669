import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background: #131313;
  height: 80px;
  margin: -8px -8px 0 -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-shadow: 0 0 8px white;
    cursor: pointer;
  }

  &:focus {
    text-shadow: 0 0 10px white;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  // only display past max-width threshold
  @media screen and (max-width: 860px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  // disappear when past thresholds
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  transition: all 0.1s ease-in-out;

  &:hover {
    text-shadow: 0 0 8px white;
    cursor: pointer;
  }

  &:focus {
    text-shadow: 0 0 10px white;
  }
`;

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid violet;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: violet;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 0 white;
    background-color: white;
    cursor: pointer;
  }

  &:focus {
    border-color: white;
    /* y axis, x axis, blur, spread, colour */
    box-shadow: 0 0 5px 0 white;
  }
`;
